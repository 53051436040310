* {
  padding: 0;
  margin: 0;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--baseFontSize);
  overflow-x: hidden;
  overflow-y: scroll;
  line-height: var(--baseLineHeight);
  /* font-size: 1.15rem; */
  color: var(--primaryColor);
  background: rgb(12,12,12) radial-gradient(circle, rgb(21,21,22) 0%, rgb(12,12,12) 100%);
}

a {
  color: var(--darkBacgkround);
  text-decoration: none;
}

[contenteditable] {
  outline: 0px solid transparent;
}

li {
  margin-left: 0rem;
}

ol, ul {
  margin-left: 1.25rem;
}

:root {
  --headlineFontSize: 1.5rem;
  --titleFontSize: 3.8rem;
  --subTitleFontSize: 2.4rem;
  --descriptionFontSize: 1.75rem;
  --descFontSize: 1.75rem;
  --baseFontSize: 1.15rem;
  --baseLineHeight: 2rem;
  --defaultSpacing: .75rem;
  --extraSpacing: 2rem;
  --primaryColor: #333;
  --lightColor: #FFFFFF;
  --lightGrayColor: #F7F7F7;
  --grayColor: #9A9A9A;
  --darkColor: #777;
  --darkBacgkround: #000000;
}

#info {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 0.75em;
}

/**
* Mobile
*/
@media only screen and (max-width: 600px) {
  :root {
    --baseFontSize: 1rem;
    --baseLineHeight: 2rem;
    --headlineFontSize: 1rem;
    --titleFontSize: 2rem;
    --subTitleFontSize: 1.4rem;
    --descriptionFontSize: 1.25rem;
    --defaultSpacing: .5rem;
    --extraSpacing: 1rem;
  }  

  .hideOnMobile {
    display: none;
  }
}

/**
* Tablet
*/
@media only screen and (min-width: 600px) and (max-width: 900px) {
  :root {
    --baseFontSize: 1rem;
    --baseLineHeight: 2rem;
    --headlineFontSize: 1rem;
    --titleFontSize: 2rem;
    --subTitleFontSize: 1.4rem;
    --descriptionFontSize: 1.25rem;
    --defaultSpacing: .5rem;
    --extraSpacing: 1rem;
  }  
}